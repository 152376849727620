import React, { useState, useEffect } from "react";

const CloudMigration = ({ data, locale }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div
      className={` ${
        isMobileView ? "cloudMigration py-3" : "cloudMigration py-5"
      }`}
    >
      <div
        className={` ${
          isMobileView ? "global-margin my-2" : "global-margin py-5"
        }`}
      >
        <div
          className={` ${
            isMobileView
              ? "line-height-30 global-title-color font-size-20 font-weight-400 text-center  my-4"
              : "cloudMigrationTitle "
          }`}
        >
          {data.title}
        </div>
        <div
          className={` ${
            isMobileView
              ? "font-size-24 font-weight-400 line-height-30 text-white  my-4 text-center"
              : "cloudMigrationHeading mb-5"
          }`}
        >
          {data.heading}
        </div>
        <div
          className={` ${
            isMobileView
              ? "cloudMigrationDescription text-center "
              : "cloudMigrationDescription "
          }`}
        >
          {data.des1}
        </div>
        <div
          className={` ${
            isMobileView
              ? "cloudMigrationDescription text-center "
              : "cloudMigrationDescription "
          }`}
        >
          {data.des2}
        </div>
        <div
          className={` ${
            isMobileView
              ? "opsBtnwrap d-flex justify-content-center"
              : "opsBtnwrap"
          }`}
        >
          <a
            href={`/successstories/Migration-Of-Et-Network-To-AWS/`}
            className="opsBtnlink"
          >
            <p>{data.btn}</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CloudMigration;
