import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";

const ExploreSuccessStories = ({ locale, successStoryData }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSuccessStoryList {
        edges {
          node {
            locale
            slug
            title
            heading
            detailDescription {
              data {
                detailDescription
              }
            }
            Industry {
              value
            }
            services {
              value
            }
            id
            image {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            description3_2 {
              data {
                description3_2
              }
            }
          }
        }
      }
    }
  `).allStrapiSuccessStoryList.edges;
  const getData = data.filter((node) => node.node.locale === locale);
  // Array of all Cloud News
  const allNews = getData;

  // State for the list
  const [list, setList] = useState([]);
  const handlefilter = () =>
    dropdownvalue.industry === "Industry" && dropdownvalue.service === "Service"
      ? allNews
      : dropdownvalue.industry === "Industry"
      ? allNews.filter(
          (val) => val.node.services?.value === dropdownvalue.service
        )
      : dropdownvalue.service === "Service"
      ? allNews.filter(
          (val) => val.node.Industry?.value === dropdownvalue.industry
        )
      : allNews.filter(
          (val) =>
            val.node.Industry?.value === dropdownvalue.industry &&
            val.node.services?.value === dropdownvalue.service
        );
  const [dropdownvalue, setDropdownvalue] = useState({
    industry: "Industry",
    service: "Service",
  });
  useEffect(() => {
    const updatedData = handlefilter();
    setList([...updatedData.slice(0, 3)]);
  }, [dropdownvalue]);
  // State to trigger Load more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 3);

  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const allNewsFilter = handlefilter();
      const isMore = currentLength < allNewsFilter.length;
      const nextResults = isMore
        ? allNewsFilter.slice(currentLength, currentLength + 3)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  //Check if there is more
  useEffect(() => {
    const allNewsFilter = handlefilter();
    const isMore = list.length < allNewsFilter.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <div
      className={` ${
        isMobileView ? "exploreSuccessStories py-2" : "exploreSuccessStories"
      }`}
      style={{ backgroundColor: "white" }}
    >
      <div className="global-margin">
        <div
          className={` ${
            isMobileView
              ? "font-size-20 font-weight-500 line-height-30 text-center global-title-color "
              : "exploreSuccessStoriesTitle "
          }`}
        >
          {successStoryData.title}
        </div>
        <div
          className={` ${
            isMobileView
              ? `font-size-24 font-weight-700 line-height-30 text-center ${
                  locale === "en" && "capitalize"
                }`
              : `exploreSuccessStoriesHeading ${
                  locale === "en" && "capitalize"
                }`
          }`}
          style={{ color: "black" }}
        >
          {successStoryData.heading}
        </div>
        {/* <p style={{ textAlign: "center" }}>Our approach process is what sets us apart </p> */}
        <div className="select_box_success">
          <select
            className={` ${isMobileView ? " my-2 w-100" : ""}`}
            onChange={(e) =>
              setDropdownvalue({ ...dropdownvalue, industry: e.target.value })
            }
          >
            {successStoryData.list.industry.map((val, index) => (
              <option
                className={` ${
                  isMobileView
                    ? "font-weight-400  font-size-14 line-height-20"
                    : "font-weight-700  font-size-20 line-height-30"
                }`}
                key={index}
              >
                {val}
              </option>
            ))}
          </select>
          <select
            className={` ${isMobileView ? "  w-100" : "mx-4 "}`}
            onChange={(e) =>
              setDropdownvalue({ ...dropdownvalue, service: e.target.value })
            }
          >
            {successStoryData.list.services.map((val, index) => (
              <option
                className={` ${
                  isMobileView
                    ? "font-weight-400  font-size-14 line-height-20"
                    : "font-weight-700  font-size-20 line-height-30"
                }`}
                key={index}
              >
                {val}
              </option>
            ))}
          </select>
        </div>
        <div className="blogsCards">
          {list.map((data, index) => (
            <div className="blogCard blogCard-card p-4" key={index}>
              <Link to={`/successstories/${data.node.slug}/`}>
                <div className="blog_img">
                  <Img
                    fixed={data.node.image.localFile?.childImageSharp.fixed}
                    alt="blog"
                    className="blogsCardImage"
                  />
                </div>
                <div
                  className={` ${
                    isMobileView
                      ? "successStoryTitle text-center"
                      : "successStoryTitle"
                  }`}
                >
                  {data.node.title}
                </div>
                <div
                  className={` ${
                    isMobileView
                      ? "successStoryHeading text-center"
                      : "successStoryHeading"
                  }`}
                >
                  {data.node.heading}
                </div>
                <div
                  className={` ${
                    isMobileView
                      ? "font-size-14 font-weight-400 line-height-30 text-black text-center"
                      : "successStoryDescription"
                  }`}
                >
                  <ReactMarkdown>
                    {`${data.node.detailDescription.data.detailDescription.slice(
                      0,
                      100
                    )} ${
                      data.node.detailDescription.data.detailDescription
                        .length > 100 && "... \n"
                    } `}
                  </ReactMarkdown>
                </div>
                <div className="resouce_content text-black">
                  <ReactMarkdown>
                    {`${data.node.description3_2?.data.description3_2.slice(
                      0,
                      300
                    )} ${
                      data.node.description3_2?.data.description3_2.length >
                        300 && "..."
                    }`}
                  </ReactMarkdown>
                </div>
              </Link>
            </div>
          ))}
        </div>
        {hasMore ? (
          <div className="center_btn">
            {" "}
            <button className="successStoryBtn" onClick={handleLoadMore}>
              {successStoryData.btn}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExploreSuccessStories;
