import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

function Heading({ data }) {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);
  return (
    <div className="upper_class">
      <div className="successStoriesSection1">
        <h4
          className={` ${
            isMobileView
              ? "successStoriesSection1Title mb-0  pt-5 text-center"
              : "successStoriesSection1Title mb-0 "
          }`}
        >
          {data.title}
        </h4>
        <h1
          className={` ${
            isMobileView
              ? "font-size-20 font-weight-400 line-height-30 text-white text-center"
              : "successStoriesSection1Heading"
          }`}
        >
          {data.heading1}{" "}
          <span style={{ color: "#58C9E8", marginBottom: "0px" }} className="">
            {data.heading2}
          </span>
        </h1>
      </div>
    </div>
  );
}

export default Heading;
