import React from "react";
import "../styles/successstories.scss";
import Layout from "../components/layoutnew";
import Heading from "../components/whyOpsio/successStories/Heading";
import CloudMigration from "../components/whyOpsio/successStories/cloudMigration";
import ExploreSuccessStories from "../components/whyOpsio/successStories/exploreSuccessStories";
import { graphql } from "gatsby";
import { useMemo } from "react";

import SEO from "../components/seo";
const SuccessStories = ({ data }) => {
  const getSuccessStoriesData = (data) => {
    const values = data.allStrapiSuccessStory.edges[0].node;
    return values;
  };
  const getData = useMemo(() => getSuccessStoriesData(data), [data]);

  const metaDes = data.allStrapiSuccessStoryCommon.edges[0].node.metaDes;

  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        description={metaDes}
        noindexForce={false}
        slug={getData.slug}
        locale={getData.locale}
        title={getData.title}
      />
      <Heading data={getData} />
      <CloudMigration locale={getData.locale} data={getData.cloudmigration} />
      <ExploreSuccessStories
        locale={getData.locale}
        successStoryData={getData.exploresuccessstory}
      />
    </Layout>
  );
};

export default SuccessStories;

export const query = graphql`
  query SuccessStories($locale: String!) {
    allStrapiSuccessStory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          heading1
          heading2
          cloudmigration {
            btn
            des1
            des2
            heading
            title
          }
          exploresuccessstory {
            btn
            list {
              industry
              services
            }
            heading
            title
          }
          title
        }
      }
    }

    allStrapiSuccessStoryCommon(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          metaDes
        }
      }
    }
  }
`;
